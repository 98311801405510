@import url("https://fonts.googleapis.com/css2?family=Nixie+One&display=swap");


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

